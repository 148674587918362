import date from 'date-and-time';

const TA_PER_KM = {
  2: 3,
  4: 6,
};

const DA_PER_DAY = {
  L5: 200,
  L6: 250,
  L7: 300,
};

export default class Trip {
  constructor(props) {
    this.id = props.id;
    this.user = props.user;
    this.startTime = props.startTime;
    this.endTime = props.endTime;
    this.submitTime = props.submitTime;
    this.serviceDuration = props.serviceDuration;
    this.routeCoordinates = Array.isArray(props.routeCoordinates)
      ? props.routeCoordinates
      : [];
    this.distanceTravelled = props.distanceTravelled || 0;
    this.claimedDistance = props.claimedDistance || null;
    this.rectifiedDistance = !isNaN(props.rectifiedDistance)
      ? props.rectifiedDistance
      : undefined;
    this.rectifiedDa = !isNaN(props.rectifiedDa) ? props.rectifiedDa : null;
    this.customers = props.customerNames
      ? props.customerNames
          .filter(customerName => customerName.trim().length)
          .map(customerName => {
            return {
              name: customerName.trim(),
            };
          })
      : props.customers || null;
    this.vehicleType = props.vehicleType;
    this.startingLocation = props.startingLocation;
    this.travelExpenses = props.travelExpenses;
    this.status = props.status;
    this.wasAutoEnded = props.wasAutoEnded || false;
    this.leadInfo =
      props.leadInfoText && props.leadInfoText.trim().length > 0
        ? [{name: '', requirements: props.leadInfoText.trim()}]
        : props.leadInfo || null;
    this.wasGPSDisabled = props.wasGPSDisabled || false;
    this.remarks = props.remarks;
    this.statusUpdateTime = props.statusUpdateTime;
    this.sameRouteCheckCount = props.sameRouteCheckCount;
    this.sameRoute = props.sameRoute;
    this.isApprovedByDepartment = props.isApprovedByDepartment || false;
    this.deviceInfo = props.deviceInfo || null;
  }

  getStartTimeFormatted() {
    if (this.startTime) {
      return date.format(this.startTime.toDate(), 'h:mm A');
    }
  }

  getTripDate() {
    if (this.startTime) {
      return date.format(this.startTime.toDate(), 'ddd, D MMM YYYY');
    }
    return date.format(this.submitTime.toDate(), 'ddd, D MMM YYYY');
  }

  getFullStartTimeFormatted() {
    if (this.startTime) {
      return date.format(this.startTime.toDate(), 'h:mm A, DD/MM/YYYY');
    }
  }

  getEndTimeFormatted() {
    if (this.endTime) {
      return date.format(this.endTime.toDate(), 'h:mm A');
    }
    return '';
  }

  getTimeElapsedFormatted() {
    if (!this.startTime) {
      return {
        hours: 0,
        minutes: 0,
      };
    }
    const startTime = this.startTime.toDate();
    const endTime = this.endTime ? this.endTime.toDate() : new Date();
    return {
      hours: Math.floor(date.subtract(endTime, startTime).toHours()),
      minutes: Math.round(date.subtract(endTime, startTime).toMinutes() % 60),
    };
  }

  getTimeElapsedInMinutes() {
    if (!this.startTime) {
      return 0;
    }
    const startTime = this.startTime.toDate();
    const endTime = this.endTime ? this.endTime.toDate() : new Date();
    return Math.round(date.subtract(endTime, startTime).toMinutes());
  }

  getServiceDuration() {
    if (this.serviceDuration === 2) {
      return 'Less than 6 hours';
    } else if (this.serviceDuration === 12) {
      return 'Half-day';
    }
    return 'Full-day';
  }

  getSubmitTimeFormatted() {
    if (this.submitTime) {
      return date.format(this.submitTime.toDate(), 'h:mm A, DD/MM/YYYY');
    }
    return '';
  }

  getApprovedTimeFormatted() {
    if (this.statusUpdateTime?.approvedTime) {
      return date.format(
        this.statusUpdateTime.approvedTime.toDate(),
        'h:mm A, DD/MM/YYYY',
      );
    }
  }

  getApprovedByModeratorTimeFormatted() {
    if (this.statusUpdateTime?.approvedByModeratorTime) {
      return date.format(
        this.statusUpdateTime.approvedByModeratorTime.toDate(),
        'h:mm A, DD/MM/YYYY',
      );
    }
  }

  getPaidTimeFormatted() {
    if (this.statusUpdateTime?.paidTime) {
      return date.format(
        this.statusUpdateTime.paidTime.toDate(),
        'h:mm A, DD/MM/YYYY',
      );
    }
  }

  getTA(isClaimedDistance) {
    if (isClaimedDistance && isNaN(this.claimedDistance)) {
      return null;
    }
    const distance = isClaimedDistance
      ? this.claimedDistance
      : !isNaN(this.rectifiedDistance)
      ? this.rectifiedDistance
      : this.distanceTravelled;

    if (this.vehicleType === 'Company vehicle') {
      return 0;
    } else if (this.vehicleType === '2-wheeler') {
      return distance * TA_PER_KM[2];
    } else if (this.vehicleType === '4-wheeler') {
      return distance * TA_PER_KM[4];
    } else if (this.travelExpenses && this.travelExpenses.length) {
      return this.travelExpenses
        .map(expense =>
          !isNaN(expense.rectifiedAmount)
            ? expense.rectifiedAmount
            : expense.amount,
        )
        .reduce((partialSum, a) => partialSum + a, 0);
    }
    return 0;
  }

  getTollAmount() {
    if (
      this.vehicleType !== 'Public transport' &&
      this.travelExpenses &&
      this.travelExpenses.length
    ) {
      return this.travelExpenses
        .map(expense =>
          !isNaN(expense.rectifiedAmount)
            ? expense.rectifiedAmount
            : expense.amount,
        )
        .reduce((partialSum, a) => partialSum + a, 0);
    }
    return 0;
  }

  getTAForClaimedDistance() {
    return this.getTA(true);
  }

  getDA(isClaimedDistance) {
    if (isClaimedDistance && isNaN(this.claimedDistance)) {
      return 0;
    }
    if (!isNaN(this.rectifiedDa) && this.rectifiedDa !== null) {
      return this.rectifiedDa;
    }
    const distance = isClaimedDistance
      ? this.claimedDistance
      : !isNaN(this.rectifiedDistance)
      ? this.rectifiedDistance
      : this.distanceTravelled;
    const endTime = this.endTime ? this.endTime.toDate() : new Date();
    const serviceDurationCalculated = this.serviceDuration
      ? this.serviceDuration
      : endTime && this.startTime
      ? date.subtract(endTime, this.startTime.toDate()).toHours()
      : 0;

    if (
      serviceDurationCalculated < 6 ||
      (typeof distance !== 'undefined' &&
        distance < 50 &&
        this.vehicleType !== 'Public transport')
    ) {
      return 0;
    } else if (
      (serviceDurationCalculated > 6 && serviceDurationCalculated < 12) ||
      (this.wasAutoEnded && (serviceDurationCalculated > 6 && serviceDurationCalculated < 15))
    ) {
      return DA_PER_DAY[this.user.level] / 2;
    } else {
      return DA_PER_DAY[this.user.level];
    }
  }

  getOriginalDA() {
    const endTime = this.endTime ? this.endTime.toDate() : new Date();
    const serviceDurationCalculated = this.serviceDuration
      ? this.serviceDuration
      : endTime && this.startTime
      ? date.subtract(endTime, this.startTime.toDate()).toHours()
      : 0;

    if (
      serviceDurationCalculated < 6 ||
      (typeof this.distanceTravelled !== 'undefined' &&
        this.distanceTravelled < 50 &&
        this.vehicleType !== 'Public transport')
    ) {
      return 0;
    } else if (
      (serviceDurationCalculated > 6 && serviceDurationCalculated < 12) ||
      (this.wasAutoEnded && (serviceDurationCalculated > 6 && serviceDurationCalculated < 15))
    ) {
      return DA_PER_DAY[this.user.level] / 2;
    } else {
      return DA_PER_DAY[this.user.level];
    }
  }

  getDAForClaimedDistance() {
    return this.getDA(true);
  }

  getTotalAllowance() {
    return this.getTollAmount() + this.getTA() + this.getDA();
  }

  getTotalAllowanceForClaimedDistance() {
    return (
      this.getTollAmount() +
      this.getTAForClaimedDistance() +
      this.getDAForClaimedDistance()
    );
  }

  getBatteryPercentage() {
    return this.batteryInfo ? Math.round(this.batteryInfo.level * 100) : 0;
  }
}
