import {createContext, useContext, useState} from 'react';

const OrganizationContext = createContext();

function OrganizationProvider({children}) {
  const [organization, setOrganization] = useState();

  return (
    <OrganizationContext.Provider value={[organization, setOrganization]}>
      {children}
    </OrganizationContext.Provider>
  );
}

function useOrganization() {
  const context = useContext(OrganizationContext);
  if (context) return context;
}

export {OrganizationProvider, useOrganization};
