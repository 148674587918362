import {firestore} from '../firebase.js';
import Trip from '../models/Trip';

let listeners = [];

export default class User {
  constructor(props) {
    this.organizationId = props.organizationId;
    this.department = props.department;
    this.email = props.email;
    this.isAdmin = props.department === 'admin';
    this.isFinance = ['accounts', 'finance'].includes(props.department);
    this.isModerator = props.department === 'moderator';
  }

  setTripsListener(
    {status, dateRange, previousLastTrip, employeeId},
    handler,
    errorHandler,
  ) {
    let query = firestore
      .collection('trips')
      .where('user.organizationId', '==', this.organizationId);
    if (this.department === 'sales') {
      query = query.where('user.department', '==', 'Sales');
    } else if (this.department === 'service') {
      query = query.where('user.department', '==', 'Service');
    } else if (this.department === 'parts') {
      query = query.where('user.department', '==', 'Parts');
    }

    if (employeeId) {
      query = query.where('user.id', '==', employeeId);
    }

    if (status) {
      query = query.where('status', 'in', status);
    }

    if (dateRange) {
      query = query
        .where('submitTime', '>=', dateRange[0])
        .where('submitTime', '<=', dateRange[1]);
    }

    if (status[0] === 'live') {
      query = query.orderBy('startTime', 'desc');
    } else {
      query = query.orderBy('submitTime', 'desc');
    }

    if (previousLastTrip) {
      query = query.startAfter(previousLastTrip);
    }

    try {
      const listener = query.limit(10).onSnapshot(tripsSnapshot => {
        const tripsFromDatabase = tripsSnapshot.docs.map(doc => {
          const trip = new Trip({
            id: doc.id,
            ...doc.data(),
          });
          return trip;
        });
        handler(tripsFromDatabase);
      });

      listeners.push(listener);
    } catch (error) {
      errorHandler(error);
    }
  }

  unsubscribeListeners() {
    listeners.map(unsubscribe => {
      return unsubscribe();
    });
    listeners = [];
  }
}
